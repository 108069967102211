import { onBeforeMount, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { handleLoadError } from '@/core/common';
import { Customer, useCustomer } from '@/modules/customer/Customer';
import { useCustomerUser } from '@/modules/customer/CustomerUser';

export const useCustomerRouteLoader = (resource: Ref, customer: Ref<Customer>) => {
	const route = useRoute();

	const load = async () => {
		if (!route.params.customer_id) {
			return;
		}

		try {
			customer.value = await useCustomer().get(route.params.customer_id as string);
			resource.value = useCustomerUser(customer.value?.id);
		} catch (e) {
			handleLoadError(e as Error, 'customer.index');
		}
	};

	watch(route, load);
	onBeforeMount(load);
};
