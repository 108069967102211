import ApiResource, { ApiParams } from '@/core/http/ApiResource';

export enum Slot {
	PublicTicketForm = 'PUBLIC_TICKET_FORM',
}

export interface CustomerCmsPage {
	exists?: boolean;
	language: string;
	slot: Slot;
	headline: string;
	text: string;
	created_time: string;
}

export class CustomerCmsPageResource {
	private readonly api: ApiResource<CustomerCmsPage>;
	private readonly baseUrl: string;

	constructor(customerId: number) {
		this.baseUrl = `/api/customer/${customerId}/cms_page`;
		this.api = new ApiResource<CustomerCmsPage>(this.baseUrl);
	}

	async getAll(params: ApiParams = {}): Promise<CustomerCmsPage[]> {
		const response = await this.api.getAll(params);

		return response.data;
	}

	async write(cmsPage: CustomerCmsPage): Promise<CustomerCmsPage> {
		return this.api.put(`${cmsPage.language}/${cmsPage.slot}`, cmsPage);
	}

	async delete(languageId: string, slot: string): Promise<void> {
		return this.api.delete(`${languageId}/${slot}`);
	}
}

export const useCustomerCmsPage = (customerId: number) => new CustomerCmsPageResource(customerId);
