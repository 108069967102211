import ApiResource from '@/core/http/ApiResource';

interface ProductAssociation {
	id: number;
}

export interface CustomerSubscription {
	id: number;
	price: number;
	notice_period: number | null;
	admins_included: number | null;
	languages_included: number | null;
	valid_from: string;
	valid_to: string | null;
	cancelled_time: string | null;
	cancelled_by_customer: boolean | null;
	last_paid_date: string | null;
	last_invoiced_date: string | null;
	paid: boolean;
	product: ProductAssociation;
	created_time: string;
}

export const useCustomerSubscription = (customerId: number | string) => new ApiResource<CustomerSubscription>(
	`/api/customer/${customerId}/subscription`,
);
